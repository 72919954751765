import React from 'react';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import Breadcrumb from '../components/innerpage/shop/Breadcrumb';
import OurShop from '../components/innerpage/shop/OurShop';

function Marketplace() {
  return (
    <>
      <HeaderOne />
      <main>
        <Breadcrumb title={'NFT Marketplace'} title2={'our marketplace'} />
        <OurShop />
      </main>
      <Footer />
    </>
  );
}

export default Marketplace;
