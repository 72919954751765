import React from 'react';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import Breadcrumb from '../components/innerpage/shop/Breadcrumb';
import Shopdetails from '../components/innerpage/shop/Shopdetails';

function RaceInfo() {
  return (
    <>
      <HeaderOne />
      <main>
        <Breadcrumb title={'race details'} title2={'race details'} />
        {/* <Shopdetails /> */}
      </main>
      <Footer />
    </>
  );
}

export default RaceInfo;
