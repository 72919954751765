import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc, getFirestore } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { app } from '../firebase.config';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

function Newsletter() {
  const [email, setEmail] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [openNotify, setOpenNotify] = React.useState(false);
  const [ipApi, setIpApi] = React.useState([]);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const { t, i18n } = useTranslation('common');

  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    // axios
    //   .get('http://ip-api.com/json/')
    //   .then((res) => {
    //     setIpApi(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // var endpoint = 'http://ip-api.com/json/';

    // var xhr = new XMLHttpRequest();
    // xhr.onreadystatechange = function () {
    //   if (this.readyState == 4 && this.status == 200) {
    //     var response = JSON.parse(this.responseText);
    //     if (response.status !== 'success') {
    //       console.log('query failed: ' + response.message);
    //       return;
    //     } else if (response.status === 'success') {
    //       setIpApi(response);
    //     }
    //   }
    // };
    // xhr.open('GET', endpoint, true);
    // xhr.send();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNotify(false);
  };

  const subscribe = async () => {
    const newSub = await addDoc(collection(db, 'subscribers'), {
      firebaseId: userId,
      email: email,
      createdAt: new Date(),
      info: ipApi
    });
    if (newSub) {
      setOpenNotify(true);
    }
  };
  return (
    <section className='newsletter-area newsletter-area-bg pb-120'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='newsletter-wrap'>
              <div className='newsletter-content'>
                <div className='newsletter-icon'>
                  <img src='assets/img/icon/newsletter_icon.png' alt='' />
                </div>
                <div className='newsletter-title'>
                  <h2 className='title'>{t('GetNotified')}</h2>
                </div>
              </div>
              <div className='d-flex flex-row newsletter-form'>
                <div className='col-lg-8 p-2 input-email'>
                  <input
                    type='email'
                    placeholder='Your Email'
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className='col-lg-4 p-2 p-l-0 newsletter-btn'>
                  <button className='btn' onClick={subscribe}>
                    <i className='fas fa-plus' />
                    SUBSCRIBE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={openNotify}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          You have subscribed successfuly!
        </Alert>
      </Snackbar>
    </section>
  );
}

export default Newsletter;
