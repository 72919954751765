import React from 'react';
import FAQComponent from '../components/FAQ';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import Banner from '../components/headerthree/Banner';
import Roadmap from '../components/headerthree/Roadmap';
import ShopArea from '../components/headerthree/ShopArea';
import StemArea from '../components/headerthree/StemArea';
import TeamMember from '../components/headerthree/TeamMember';
import Newsletter from '../components/Newsletter';

function FAQ() {
  return (
    <>
      <HeaderOne />
      <main>
        <FAQComponent />
        <Newsletter />
      </main>

      <Footer />
    </>
  );
}

export default FAQ;
