import React from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { app } from "../../../firebase.config";
import { addDoc, collection, collectionGroup, query, getFirestore, getDocs, where } from "firebase/firestore";
import * as DappUI from "@elrondnetwork/dapp-core/UI"
import { useGetLoginInfo, useGetAccountInfo} from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';

function Breadcrumb() {
  const firestoreDB = getFirestore(app);
  const { address, account } = useGetAccountInfo();
  const [paymentData, setPaymentData] = React.useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const paymentIntentParams = searchParams.get('payment_intent');
  const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
  const paymentRedirectStatus = searchParams.get('redirect_status');

  React.useEffect(async () => {
    const races = query(collectionGroup(firestoreDB, 'payments'), where('paymentIntentParams', '==', paymentIntentParams));
    const querySnapshot = await getDocs(races);
    // console.log(querySnapshot.size)

    if (!querySnapshot.size) {
      if (paymentRedirectStatus === 'succeeded') {
        console.log('Payment succeeded!');
        const docData = {
          paymentIntentParams,
          paymentIntentClientSecret,
          paymentRedirectStatus,
          address,
          createdAt: new Date(),
        }
        const customer = {
          customer: {
            email: account.email,
            name: address,
            address: address,
          }
        }
        addDoc(collection(firestoreDB, 'users/' + address + '/payments'), docData).then(() => {
          setPaymentData({ timestamp: Date.now(), status: "success", docData: docData })
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(customer)
        };
          fetch('https://api.erms.ro/api/stripe/create-customer', requestOptions)
        })
      }
    }
  }, []);
  return (
    <section className='breadcrumb-area breadcrumb-bg breadcrumb-bg-three'>
      <div className='breadcrumb-overflow'>
        <div className='container'>
          <div className='breadcrumb-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-4 col-sm-6 order-2 order-lg-1'>
                <Link to='/profile'>
                  <div className='breadcrumb-img-three text-center'>
                    <img src='assets/img/images/breadcrumb_team_bg.png' alt='' />
                    <img
                      src='assets/img/images/team_logo01.png'
                      alt=''
                      className='team-logo'
                    />
                    <h4 className='team-name'>View your collection</h4>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 order-0 order-lg-1'>
                <div className='breadcrumb-content'>
                  <h2 className='title'>Payment successful!</h2>
                  <div className='coming-time' data-countdown='2022/5/29' />
                </div>
              </div>
              <div className='col-lg-4 col-sm-6 order-3 order-lg-2'>
                <Link to='/marketplace'>
                  <div className='breadcrumb-img-three right-team text-center'>
                    <img src='assets/img/images/breadcrumb_team_bg.png' alt='' />
                    <img
                      src='assets/img/images/team_logo02.png'
                      alt=''
                      className='team-logo'
                    />
                    <h4 className='team-name'>Buy another NFT</h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumb;
