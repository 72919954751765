import React from "react";
import { app } from "../../firebase.config";
import { collection, collectionGroup, query, where, getDocs, getFirestore, limit } from "firebase/firestore";
import moment from 'moment';
import axios from "axios";
import Address from "../Address";
import NftImage from "components/NftImage";

const db = getFirestore(app);
const environment = process.env.REACT_APP_ENVIRONMENT;

//sort an array of objects by a raceTime 
const sortByTime = (arr) => {
    return arr.sort((a, b) => {
        return a.raceTime - b.raceTime;
    }
    );
}

const TopPlayersList = () => {
    const [topPlayers, setTopPlayers] = React.useState([]);
    const [username, setUsername] = React.useState('');





    const cut = (user) => {
        return user.substr(0, 6) + '...' + user.substr(-5, 5);
    }

    const count = (number) => {
        //add st nd rd th to the end of the number
        if (number === 1) {
            return number + `st`;
        } else if (number === 2) {
            return number + `nd`;
        } else if (number === 3) {
            return number + `rd`;
        } else {
            return number + `th`;
        }
    }





    React.useEffect(async () => {
        const firestoreDB = getFirestore(app);
        const races = query(collectionGroup(firestoreDB, 'races'), limit(1000));
        const querySnapshot = await getDocs(races);
        const topPlayersArr = [];
        const unsubscribe = querySnapshot.forEach((doc) => {
            const docData = doc.data();
            const endTime = docData.endTime;
            const raceTime = docData.raceTime;
            if (raceTime) {
                // docData.raceTime = docData.endTime - docData.startTime;
                // setTopPlayers(prevState => [...prevState, docData]);
                topPlayersArr.push(docData);
                //order top players by raceTime
                // console.log("Race time:" + docData.raceTime)
            }
        });
        //filter out the top 10 players and unique addresses
        const topPlayersArrSorted = sortByTime(topPlayersArr);
        
        const topPlayersArrFilteredUnique = topPlayersArrSorted.filter((player, index, self) =>
            index === self.findIndex((t) => (
                t.address === player.address
            ))
        )
        setTopPlayers(topPlayersArrFilteredUnique);

        return () => unsubscribe();

    }, []);

    return (
        <>
            {topPlayers.map((player, key) => {
                return (
                    <div key={key} className={"d-flex bg-secondary mb-2 flex-row align-items-center justify-content-around " + (key == 0 ? " top1 " : "" + key == 1 ? " top2 " : "" + key == 2 ? " top3 " : "") + (key % 2 == 0 && " bg-dark ")}>
                        <div className={["col-md-2"]}>
                            <span className="top-player-pos">{count(key + 1)}</span>
                        </div>
                        <div className={["col-md-2"]}>
                            <NftImage playerAddress={player.address} />
                        </div>
                        <div className={["col-md-6 d-flex flex-column flex-md-row"]}>
                            <div className="col-md-6">
                                <span className="top-player-username"><Address user={player.address} /></span>
                            </div>
                            <div className="col-md-6">
                                <span className="top-player-address">{cut(player.address)} </span>
                            </div>
                        </div>
                        {/* <div className="col-md-2">
                            <span>{player.raceId}</span>
                        </div>  */}
                        {/* <div className="col-md-2">
                            <span>{player.raceName}</span>
                        </div> */}
                        <div className="col-md-2">
                            <span className="top-player-time">{moment.utc(player.raceTime * 1000).format('HH:mm:ss:SSS')}</span>
                        </div>
                    </div>
                )
            })
            }
        </>
    )
}

export default TopPlayersList;