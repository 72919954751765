import React from "react";
import { useNavigate } from 'react-router-dom';
import { createHash } from "crypto";

const Dev = () => {
    const navigate = useNavigate();
    const pass = process.env.REACT_APP_PP;

    const hash = (string) => {
        return createHash('sha256').update(string).digest('hex');
    }

    const check = (event) => {
        if (event.target.value === pass) {
            sessionStorage.setItem("allow", hash(pass));
            navigate('/');
        }
        // console.log(event.target.value);
    }
    return (
        <div className='container d-flex justify-items-center align-items-center h-100'>
            <input type='text' placeholder='p' className='form-control' onChange={check} />
            Dev
        </div>
    );
}
export default Dev;