import React from 'react';
import * as DappUI from "@elrondnetwork/dapp-core/UI"
import { useGetLoginInfo, useGetAccountInfo} from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import {
  doc,
  setDoc,
  getFirestore,
  getDoc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import AdminComponent from 'components/innerpage/admin/AdminComponent';
import Breadcrumb from 'components/innerpage/admin/Breadcrumb';
import Counter from '../components/Counter';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import { app } from '../firebase.config';

function Admin() {
  const { isLoggedIn } = useGetLoginInfo();
  const navigate = useNavigate();
  const { address, account } = useGetAccountInfo();
  const db = getFirestore(app);
  React.useEffect(async () => {
    if (isLoggedIn) {
      const usersRef = doc(db, 'users', address);
      const docSnap = await getDoc(usersRef);
      const isAdmin = docSnap.data().admin;
      console.log(isAdmin);
      if (!isAdmin) {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, []);
  return (
    <>
      <HeaderOne />
      <main>
        <Breadcrumb title={'You Admin profile'} title2={'NFTs'} />
        <AdminComponent />
      </main>

      <Footer />
    </>
  );
}

export default Admin;
