import React from 'react';

function StemArea() {
  return (
    <section className='stem-area stem-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8 text-center'>
            <div className='stem-content'>
              <div className='stem-icon'>
                <a href='https://www.twitch.tv/videos/1261623067'>
                  <i className='far fa-play-circle' />
                </a>
              </div>
              <h2 className='title'>Online Perfect Stem</h2>
              <span>the Season 8</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StemArea;
