import './index.css';

import React from 'react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import common_en from './translations/en/common.json';
import common_ro from './translations/ro/common.json';

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false }, // React already does escaping
  resources: {
    en: {
      common: common_en // 'common' is our custom namespace
    },
    ro: {
      common: common_ro
    }
  }
});
ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);
