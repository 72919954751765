import React from 'react';
import Typography from '@mui/material/Typography';

function AirDropComponent() {
  return (
    <section className='countdown-area-two countdown-bg airdrop-container'>
      <div className='container h-100'>
        <div className='row justify-content-center h-100'>
          <div className='col-lg-12'>
            <div className='countdown-wrap text-center h-100'>
              <h1>First AirDrop Winners</h1>
              <div className='p-2 h-100'>
                <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSJ85HRJ_R6Oxcm2VD8J5pyGPbuGVhYgp2yYt6QEP2a8SgTU6QVaoo3lfDR8MFiCPe4y_v-3pWjdiyC/pubhtml?widget=true&amp;headers=false" className='w-100 h-100 airdrop-iframe'></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AirDropComponent;
