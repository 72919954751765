import React from 'react';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import Breadcrumb from '../components/innerpage/checkout/Breadcrumb';

function CheckoutSuccess() {
  return (
    <>
      <HeaderOne />
      <main>
        <Breadcrumb />
      </main>
      <Footer />
    </>
  );
}

export default CheckoutSuccess;
