import React from 'react';
import { app } from "../../../../firebase.config";
import { addDoc, collection, collectionGroup, query, getFirestore, getDocs, where } from "firebase/firestore";
import moment from 'moment';

const PaymentsList = () => {
    const firestoreDB = getFirestore(app);
    const [payments, setPayments] = React.useState([]);

    React.useEffect(async () => {
        const payments = query(collectionGroup(firestoreDB, 'payments'));
        const querySnapshot = await getDocs(payments);
        querySnapshot.forEach((doc) => {
            const docData = doc.data();
            console.log(docData);
            setPayments(prevState => [...prevState, docData]);
        }
        );
    }, []);

    return (
        <div>
            <h1>PaymentsList</h1>
            {payments.map((payment, key) => {
                // console.log(payment);
                const elrondExplorerLink = 'https://explorer.elrond.com/accounts/'+payment.address+'/nfts';
                return (
                    <div key={key} className='d-flex flex-row'>
                        <div className="col-md-8">
                            <a href={elrondExplorerLink} target='_blank'>
                                <span>{payment.address}</span>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <span>{moment.unix(payment.createdAt.seconds).format('MMMM Do YYYY, h:mm:ss a')}</span>
                        </div>
                        <div className="col-md-2">
                            <span>{payment.paymentRedirectStatus}</span>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}
export default PaymentsList;