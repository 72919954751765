import React from 'react';
import * as DappUI from "@elrondnetwork/dapp-core/UI"
import { useGetLoginInfo, useGetAccountInfo} from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from 'components/innerpage/profile/Breadcrumb';
import ProfileComponent from 'components/innerpage/profile/Profile';
import Counter from '../components/Counter';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';

function Profile() {
  const { isLoggedIn } = useGetLoginInfo();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, []);
  return (
    <>
      <HeaderOne />
      <main>
        <Breadcrumb title={'Your profile'} title2={'NFTs'} />
        <ProfileComponent />
      </main>

      <Footer />
    </>
  );
}

export default Profile;
