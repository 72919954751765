import React from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#212121',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    letterSpacing: '0.1rem',
    color: '#fff'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: '#000',
  letterSpacing: '0.5px',
  color: '#fff',
  textAlign: 'justify'
}));

function FAQComponent() {
  const [expanded, setExpanded] = React.useState('panel1');
  const { t, i18n } = useTranslation('common');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <section className='countdown-area-two countdown-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-12'>
            <div className='countdown-wrap text-center'>
              <div>
                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary
                    aria-controls='panel1d-content'
                    id='panel1d-header'
                  >
                    <Typography variant='bold'>
                      {t('FAQPage.question1')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant='bold'>
                      {t('FAQPage.answer1-1')}
                      <br />
                      <br />
                      <ul>
                        <li>{t('FAQPage.answer1-2')}</li>
                        <li>{t('FAQPage.answer1-3')}</li>
                        <li>{t('FAQPage.answer1-4')}</li>
                        <li>{t('FAQPage.answer1-5')}</li>
                        <li>{t('FAQPage.answer1-6')}</li>
                        <li>{t('FAQPage.answer1-7')}</li>
                      </ul>
                      <br />
                      <br />
                      <div>
                        <a
                          href='https://elrond.com'
                          target='_blank'
                          rel='noreferrer'
                        >
                          https://elrond.com
                        </a>
                      </div>
                      <br />
                      <br />
                      <div>{t('FAQPage.answer1-8')}</div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                >
                  <AccordionSummary
                    aria-controls='panel2d-content'
                    id='panel2d-header'
                  >
                    <Typography variant='bold'>
                      {t('FAQPage.question2')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant='bold'>
                      {t('FAQPage.answer2-1')}
                      <a
                        href='https://maiar.com/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        &nbsp;https://maiar.com/
                      </a>
                      <br />
                      <br />
                      {t('FAQPage.answer2-2')}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3')}
                >
                  <AccordionSummary
                    aria-controls='panel3d-content'
                    id='panel3d-header'
                  >
                    <Typography variant='bold'>
                      {t('FAQPage.question3')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant='bold'>
                      {t('FAQPage.answer3-1')}
                      <br />
                      <br />
                      <ul>
                        <li>{t('FAQPage.answer3-2')}</li>
                        <li>{t('FAQPage.answer3-3')}</li>
                      </ul>
                      <br />
                      <br />
                      {t('FAQPage.answer3-4')}
                      <ul>
                        <li>{t('FAQPage.answer3-5')}</li>
                        <li>{t('FAQPage.answer3-6')}</li>
                        <li>{t('FAQPage.answer3-7')}</li>
                      </ul>
                      {t('FAQPage.answer3-8')}
                      <br />
                      <br />
                      {t('FAQPage.answer3-9')}
                      <ul>
                        <li>{t('FAQPage.answer3-10')}</li>
                      </ul>
                      <br />
                      <br />
                      {t('FAQPage.answer3-11')}
                      <ul>
                        <li>{t('FAQPage.answer3-12')}</li>
                      </ul>
                      <br />
                      <br />
                      {t('FAQPage.answer3-13')}
                      <ul>
                        <li>{t('FAQPage.answer3-14')}</li>
                        <li>{t('FAQPage.answer3-15')}</li>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQComponent;
