import React from 'react';
// import htmlGame from './game/index.html'

const PageNotFoundGame = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>Ooops!</div>
      </div>
    </>
  );
};

export default PageNotFoundGame;
