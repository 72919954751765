import React from 'react';
import Counter from '../components/Counter';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import Banner from '../components/headerthree/Banner';
import Roadmap from '../components/headerthree/Roadmap';
import ShopArea from '../components/headerthree/ShopArea';
import StemArea from '../components/headerthree/StemArea';
import TeamMember from '../components/headerthree/TeamMember';
import Newsletter from '../components/Newsletter';
import TopPlayersComponent from '../components/TopPlayers';

function TopPlayers() {
  return (
    <>
      <HeaderOne />
      <main>
        <TopPlayersComponent />
        {/* <Newsletter /> */}
      </main>

      <Footer />
    </>
  );
}

export default TopPlayers;
