import TopPlayersListWidget from 'components/topPlayers/TopPlayersListWidget';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

function PrevArrow(props) {
  const { className, onClick } = props;

  return (
    <button type='button' className={className} onClick={onClick}>
      <i className='fas fa-angle-left'></i>
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}>
      <i className='fas fa-angle-right'></i>
    </button>
  );
}
function Tournament() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };
  const { t, i18n } = useTranslation('common');
  return (
    <section className='tournament-area pb-120 pt-120'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='tournament-content'>
              <h6 className='sub-title'>{t('Tournament.title1')}</h6>
              <h4 className='title'>{t('Tournament.title2')}</h4>
              <p>{t('Tournament.content1')}</p>
              <a href='/marketplace' className='btn'>
                {t('Tournament.Marketplace')}
              </a>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='prize-pool'>
              <h3 className='title'>{t('Tournament.PrizePool')}</h3>
              <h5 className='prize'>$10.000</h5>
              {/* <div className='coming-time' data-countdown='2022/5/29' /> */}
              <img src='assets/img/images/prize_img.png' alt='' />
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='winner-wrap'>
              <h2 className='title'>{t('Tournament.LastWinners')}</h2>
              <Slider className='winner-active' {...settings}>
                <div className='d-flex flex-row align-items-center justify-content-around '>
                  <TopPlayersListWidget />
                </div>

              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tournament;
