import React from "react";
import { app } from "../firebase.config";
import { collection, collectionGroup, query, where, getDocs, getFirestore, limit } from "firebase/firestore";
import moment from 'moment';
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";

const environment = process.env.REACT_APP_ENVIRONMENT;
var collectionsEnv = '';
var elrondUri = '';
if (environment === 'testnet') {
    elrondUri = 'https://api.elrond.com/';
    collectionsEnv = 'collections_dev';
} else if (environment === 'mainnet') {
    elrondUri = 'https://api.elrond.com/';
    collectionsEnv = 'collections_prod';
} else if (environment === 'devnet') {
    elrondUri = 'https://api.elrond.com/';
    collectionsEnv = 'collections_dev';
}
const db = getFirestore(app);
// const remoteConfig = getRemoteConfig(app);
// fetchAndActivate(remoteConfig);
// const remoteConfigValue = getValue(remoteConfig, collectionsEnv);
// remoteConfig.settings.minimumFetchIntervalMillis = 600000;
// const remoteValue = remoteConfigValue._value;

const NftImage = ({ playerAddress }) => {


    // console.log(playerAddress)
    const [nftImgSrc, setNftImgSrc] = React.useState('');

    React.useEffect(() => {
        if (nftImgSrc === '' && environment === 'mainnet') {
            try {
                fetch(elrondUri + 'accounts/' + playerAddress + '/nfts').then((res) => {
                    // console.log(res)
                    res.json().then((data) => {
                        // console.log(data);
                        // console.log(collections[0].name);
                        const currentNft = data.find(item => item.collection === "ERMS-663664");
                        // console.log(currentNft);
                        if (currentNft) {
                            setNftImgSrc(currentNft.media[0].thumbnailUrl);
                        }
                        // if (data.find(item => item.collection === collections[0].name)) {
                        //     console.log('has nft in collection ' + collections[0].name);
                        //     setNfts(data);
                        //     setCollectionName(collections[0].name);
                        // }
                    });
                });
            } catch (error) {
                console.log(error);
            }
        }
    }, [nftImgSrc]);
    return (
        <>
            {nftImgSrc && <img src={nftImgSrc} alt="nft" className="nft-thumbnail" />}
        </>
    )

    // if (nftImgSrc) {
    //     return <img src={nftImgSrc} alt="nft" />
    // } else {
    //     return <img src='' alt="nft" />
    // }
}

export default NftImage;