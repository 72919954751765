import React from 'react';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import Breadcrumb from '../components/innerpage/verify/Breadcrumb';
import VerifyComponent from 'components/innerpage/verify/VerifyComponent';

function Verify() {
  return (
    <>
      <HeaderOne />
      <main>
        <Breadcrumb />
        <VerifyComponent />
      </main>
      <Footer />
    </>
  );
}

export default Verify;
