import React from 'react';
import '../../styles/animate.min.css';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Roadmap() {
  const { t, i18n } = useTranslation('common');
  return (
    <section className='roadmap-area'>
      <div className='container'>
        <div className='row justify-content-center justify-content-xl-end'>
          <div className='col-12'>
            <div className='section-title title-style-three'>
              <h2 className='title'>Roadmap</h2>
            </div>
          </div>
          <div className='col-xl-9 col-lg-11'>
            <div className='roadmap-timeline-wrap'>
              <ul>
                <li>
                  <div className='sold-percentage'>
                    <h2 className='title faq'>{t('roadmap.time1')}</h2>
                  </div>
                  <div
                    className='timeline-content wow fadeInRight'
                    data-wow-delay='.2s'
                  >
                    <div className='image d-flex justify-content-center'>
                      <img
                        className='roadmap-img'
                        src='assets/img/logo/logo.png'
                        alt=''
                      />
                    </div>
                    <p>
                      {t('roadmap.text1-1')}
                      <br />
                      <br />
                      {t('roadmap.text1-2')}
                    </p>
                  </div>
                </li>
                <li>
                  <div className='sold-percentage'>
                    <h2 className='title'>August 2022</h2>
                  </div>
                  <div
                    className='timeline-content wow fadeInRight'
                    data-wow-delay='.4s'
                  >
                    {/* <div className='sold-img'>
                      <img
                        className='roadmap-img'
                        src='assets/img/images/game2.png'
                        alt=''
                      />
                    </div> */}
                    <p>{t('roadmap.text2-1')}</p>
                  </div>
                </li>
                <li>
                  <div className='sold-percentage'>
                    <h2 className='title'>{t('roadmap.time3')}</h2>
                  </div>
                  <div
                    className='timeline-content wow fadeInRight'
                    data-wow-delay='.2s'
                  >
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                      <Accordion.Item eventKey='0'>
                        <Accordion.Header>
                          {t('roadmap.PrivateSale')}
                        </Accordion.Header>
                        <Accordion.Body>
                          <h5 className='title'>{t('roadmap.textPrivateSale')}</h5>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>
                          {t('roadmap.PublicSale')}
                        </Accordion.Header>
                        <Accordion.Body>
                          <h5 className='title'>{t('roadmap.text3')}</h5>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </li>
                <li>
                  <div className='sold-percentage'>
                    <h2 className='title'>December 2022</h2>
                  </div>
                  <div
                    className='timeline-content wow fadeInRight'
                    data-wow-delay='.4s'
                  >
                    <h5 className='title'>{t('roadmap.text4')}</h5>
                  </div>
                </li>
                <li>
                  <div className='sold-percentage'>
                    <h2 className='title'>January 2023</h2>
                  </div>
                  <div
                    className='timeline-content wow fadeInRight'
                    data-wow-delay='.4s'
                  >
                    <h5 className='title'>{t('roadmap.text5')}</h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className='col-12'>
            <div className='timeline-bottom-image animate__animated animate__fadeInUp animate__delay-.2s d-flex d-flex-column align-items-center'>
              <img
                src='assets/img/images/timeline_bottom_img.jpg'
                alt=''
                className='main-img'
              />
              <img
                src='assets/img/images/timeline_shape.png'
                alt=''
                className='shape'
              />
              <a href='/getting-started' className='btn'>
                Getting started
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
