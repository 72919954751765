import React from 'react';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import * as DappUI from "@elrondnetwork/dapp-core/UI"
import { useGetLoginInfo, useGetAccountInfo} from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from './CheckoutForm';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { routeNames } from 'routes';
import { Link } from 'react-router-dom';
import { doc, setDoc, getFirestore, getDoc } from 'firebase/firestore';
import { app } from '../../../firebase.config';


const {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} = DappUI;

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#ff8503'),
  fontWeight: 'bold',
  backgroundColor: '#ff8503',
  '&:hover': {
    backgroundColor: '#e19b51'
  }
}));

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#ff8503'
  },
  '& label.MuiInputBase-root': {
    color: '#ff8503'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ff8503'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ff8503'
    },
    '&:hover fieldset': {
      borderColor: '#ff8503',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ff8503'
    }
  },
  '& .MuiInputLabel-root': {
    color: '#ff8503'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    backgroundColor: '#000000',
    borderRadius: '1px',
    border: '1px solid #fff'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#000000' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC
);


function OurShop() {
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);
  const [currentTabValue, setCurrentTabValue] = React.useState(1);
  const { isLoggedIn } = useGetLoginInfo();
  const { address, account } = useGetAccountInfo();
  const [emailCapture, setEmailCapture] = React.useState('');
  const [addressCapture, setAddressCapture] = React.useState('');
  const [emailValid, setEmailValid] = React.useState(false);
  const db = getFirestore(app);

  const handleChangeTab = (event, newValue) => {
    setCurrentTabValue(newValue);
  };

  React.useEffect(async () => {
    if (address) {
      setAddressCapture(address);
      const docRef = await getDoc(doc(db, 'users', address));
      if (docRef) {
        const userData = docRef.data();
        if (userData) {
          const settedEmail = userData.email;
          if (!settedEmail) {
            console.log('no email');
          } else{
            setEmailCapture(settedEmail);
            setEmailValid(true);
          }
        }
      }
    }
  }, []);


  const nfts = [
    {
      src: 'assets/img/nft/AmoDoBazooka.PNG',
      name: 'Intruder',
      price: '0.1'
    },
    {
      src: 'assets/img/nft/CargusRadioactivus.PNG',
      name: 'Black Bird',
      price: '0.1'
    },
    {
      src: 'assets/img/nft/Commando.PNG',
      name: 'Commando',
      price: '0.1'
    },
    {
      src: 'assets/img/nft/Interceptor.PNG',
      name: 'Interceptor',
      price: '0.1'
    },
    {
      src: 'assets/img/nft/Lightning.png',
      name: 'Lightning',
      price: '0.1'
    },
    {
      src: 'assets/img/nft/PistoletoBlindato.PNG',
      name: 'Daytona',
      price: '0.1'
    },
    {
      src: 'assets/img/nft/SilverHawk.PNG',
      name: 'SilverHawk',
      price: '0.1'
    },
    {
      src: 'assets/img/nft/SpitFire.PNG',
      name: 'SpitFire',
      price: '0.1'
    },
    {
      src: 'assets/img/nft/Thunderbolt.PNG',
      name: 'Thunderbolt',
      price: '0.1'
    }
  ];

  const [clientSecret, setClientSecret] = React.useState("");

  const createPaymentIntent = async () => {
    const environment = process.env.NODE_ENV;
    var url = "";
    if (environment === "production") {
      url = "https://api.erms.ro/api/stripe/create-payment";
    } else {
      url = "https://dev.api.erms.ro/api/stripe/create-payment";
    }
    return fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "NFT" }], customer: { name: address, email: emailCapture } }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClientSecret(data.clientSecret)
        return true;
      });
  }

  React.useEffect(() => {
    if (isLoggedIn) {
      createPaymentIntent();
    }
  }, [isLoggedIn]);

  const appearance = {
    theme: 'night',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(!openPaymentDialog);
  };

  const WalletConnectButton = () => {
    return (
      <>
        <div className='d-flex justify-content-center'>
          <ExtensionLoginButton
            loginButtonText={'Extension'}
            callbackRoute={routeNames.marketplace}
          />
          <WebWalletLoginButton
            loginButtonText={'Web wallet'}
            callbackRoute={routeNames.marketplace}
          />
        </div>
        <div className='d-flex justify-content-center'>
          <LedgerLoginButton
            loginButtonText={'Ledger'}
            className={'test-class_name'}
            callbackRoute={routeNames.marketplace}
          />
          <WalletConnectLoginButton
            loginButtonText={'Maiar'}
            callbackRoute={routeNames.marketplace}
          />
        </div>
      </>
    );
  }

  const emailEntered = async () => {
    if (emailCapture) {
      if (await createPaymentIntent()) {
        setEmailValid(true);
      }
    }
    // createPaymentIntent();
    console.log('out')
  }

  const PaymentInfo = () => {
    return (
      <>
        {!isLoggedIn && (
          <>
            <div className='d-flex justify-content-center text-white p-2'>
              Login with your wallet to be able to buy with your credit/debit card
            </div>
            <WalletConnectButton />
            {/* <div className='d-flex justify-content-center text-white p-2'>
              -- or enter your details --
            </div>
            <div className='d-flex flex-column justify-content-center'>
              <div className='p-2'>
                <CssTextField
                  key='email'
                  label='Your Email'
                  id='custom-css-outlined-input'
                  sx={{ input: { color: 'white' }, paddingRight: 2 }}
                  type='email'
                  onChange={(e) => setEmailCapture(e.target.value)}
                  value={emailCapture}
                  onBlur={emailEntered}
                />
              </div>
              <div className='p-2'>
                <CssTextField
                  key='address'
                  label='Your Maiar Wallet Address'
                  id='custom-css-outlined-input'
                  sx={{ input: { color: 'white' }, paddingRight: 2 }}
                  type='text'
                  onChange={(e) => setAddressCapture(e.target.value)}
                />
              </div>
            </div> */}
          </>
        )}


      </>
    );
  }

  const BuyWithCard = () => {
    return (
      <>

        {clientSecret && (
          <>
            {/* {emailValid && ( */}
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm email={emailCapture} address={addressCapture} />
            </Elements>
            {/* )} */}
          </>
        )}
        <div className='d-flex justify-content-center text-white'>
          Secured Payment Provided by Stripe
        </div>
      </>
    )
  }

  const BuyWithCrypto = () => {
    return (
      <>
        {isLoggedIn ?
          (
            <div className='d-flex justify-content-center'>
              <a href='https://xoxno.com/buy/ERMS/Assets' target='_blank' className='btn'>Go to XOXNO Marketplace</a>
            </div>
          )
          : (
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <div className='p-2'>
                <a href='https://xoxno.com/buy/ERMS/Assets' target='_blank' className='btn'>Mint NFT on XOXNO</a>
              </div>
              <div className='p-2'>- or connect wallet -</div>
              <div>
                <WalletConnectButton />
              </div>
            </div>
          )}
      </>
    );
  }




  return (
    <section className='inner-shop-area black-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-12'>
            <div className='shop-top-meta'>
              <p className='shop-show-result'>Total Items {nfts.length}</p>
              <div className='shop-top-right'>
                <form action='#'>
                  <label htmlFor='shortBy'>sort By</label>
                  <select
                    id='shortBy'
                    name='select'
                    className='form-select'
                    aria-label='Default select example'
                  >
                    <option value>Sort by Latest</option>
                    <option>Free Shipping</option>
                    <option>Best Match</option>
                    <option>Newest Item</option>
                    <option>Size A - Z</option>
                  </select>
                </form>
              </div>
            </div>
            <div className='row justify-content-start'>
              {nfts.map((nft, key) => {
                return (
                  <div className='col-md-3 col-sm-6' key={key}>
                    <div className='shop-item mb-60'>
                      <div className='shop-thumb'>
                        <img
                          src='assets/img/shop/shop_wrap_img02.png'
                          alt='img'
                          className='shape'
                        />
                        <span
                          role='button'
                          onClick={handleClosePaymentDialog}
                        >
                          <img
                            src={nft.src}
                            alt='img'
                            className='shop-img nft-img'
                          />
                        </span>
                        <div className='shop-date'>
                          <span className='text-decoration-line-through'>0.5 EGLD</span>
                        </div>
                        {/* <div className='shop-share'>
                          <a href='https://xoxno.com/buy/ERMS/Assets'>
                            <i className='fas fa-share-alt' />
                          </a>
                        </div> */}
                      </div>
                      <div className='shop-content'>
                        <div className='shop-content-top'>
                          <div className='shop-title'>
                            <h5 className='title'>
                              <span
                                role="button"
                                onClick={handleClosePaymentDialog}
                              >
                                {nft.name}
                              </span>
                            </h5>
                            {/* <p>E-SPORTS</p> */}
                          </div>
                          <div className='price'>
                            <span>{nft.price} EGLD</span>
                          </div>
                        </div>
                        <div className='shop-content-bottom'>
                          <div className='shop-btn' >
                            <span
                              className='btn'
                              role="button"
                              onClick={handleClosePaymentDialog}
                            >
                              <i className='fas fa-plus' />
                              get nft
                            </span>
                          </div>
                          <div className='shop-wishlist'>
                            <a href='https://xoxno.com/buy/ERMS/Assets '>
                              <i className='far fa-heart' />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className='pagination-wrap mt-10'>
                <nav>
                  <ul>
                    <li className='active'>
                      <a href='/#'>01</a>
                    </li>
                    <li>
                      <a href='/#'>02</a>
                    </li>
                    <li>
                      <a href='/#'>03</a>
                    </li>
                    <li>
                      <a href='/#'>04</a>
                    </li>
                    <li>
                      <a href='/#'>Next</a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
          </div>
          {/* <div className='col-xl-4 col-lg-6'>
            <aside className='shop-sidebar'>
              <div className='widget'>
                <h4 className='widget-title'>Searching</h4>
                <form action='#' className='sidebar-search'>
                  <input type='text' placeholder='Search...' />
                  <button>
                    <i className='fas fa-search' />
                  </button>
                </form>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>price filter</h4>
                <div className='price_filter'>
                  <div id='slider-range' />
                  <div className='price_slider_amount'>
                    <span>Price :</span>
                    <input
                      type='text'
                      id='amount'
                      name='price'
                      placeholder='Add Your Price'
                    />
                  </div>
                  <input type='submit' className='btn' defaultValue='Filter' />
                </div>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>CATEGORIES</h4>
                <ul className='shop-sidebar-cat'>
                  <li>
                    <a href='/#'>
                      E-SPORTS <span>26</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      xbox controller <span>24</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      Head phone <span>06</span>
                    </a>
                  </li>
                  <li>
                    <a href='/#'>
                      Gaming Mask <span>12</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='widget'>
                <h4 className='widget-title'>tagS</h4>
                <ul className='shop-sidebar-tags'>
                  <li>
                    <a href='/#'>game</a>
                  </li>
                  <li>
                    <a href='/#'>offline</a>
                  </li>
                  <li>
                    <a href='/#'>online gaming</a>
                  </li>
                  <li>
                    <a href='/#'>shooting</a>
                  </li>
                  <li>
                    <a href='/#'>online games</a>
                  </li>
                  <li>
                    <a href='/#'>X-box</a>
                  </li>
                  <li>
                    <a href='/#'>zombie games</a>
                  </li>
                </ul>
              </div>
            </aside>
          </div> */}
        </div>
      </div>
      <BootstrapDialog
        onClose={handleClosePaymentDialog}
        aria-labelledby='customized-dialog-title'
        open={openPaymentDialog}
        style={{ zIndex: 100 }}
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClosePaymentDialog} >
          <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>
            Get your NFT
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}
        >
          <div className='d-flex flex-column justify-content-center'>
            {/* <CssTextField
              label='Your Email'
              id='custom-css-outlined-input'
              sx={{ input: { color: 'white' }, paddingRight: 2 }}
              autoFocus
              type='email'
              onChange={(e) => setEmailCapture(e.target.value)}
            /> */}
            <div className=''>
              <span className='text-white'>
                Benefits of owning an NFT in Pre-sale: <br />
                <ul>
                  <li>
                    You can get your NFT in the Pre-sale at a discounted price.
                  </li>
                  <li>
                    Price reduced from 0.5 EGLD to 0.1 EGLD for the first buyers
                  </li>
                  <li>
                    Staking rewards in <Link to='/erms'>ERMS</Link>
                  </li>
                </ul>
              </span>
            </div>
            <div className="payment">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={currentTabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                    {/* <Tab sx={{ color: '#ffffff' }} label="Buy with card" {...a11yProps(0)} /> */}
                    <Tab sx={{ color: '#ffffff' }} label="Buy with crypto" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={currentTabValue} index={0}>
                  <PaymentInfo />
                  <BuyWithCard />
                </TabPanel>
                <TabPanel value={currentTabValue} index={1}>
                  <BuyWithCrypto />
                </TabPanel>
              </Box>

            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </section>
  );
}

export default OurShop;
