import React from 'react';
import { AuthenticatedRoutesWrapper } from '@elrondnetwork/dapp-core/wrappers';
import { useLocation } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import { useNavigate } from 'react-router-dom';
import { createHash } from "crypto";
import SEO from 'react-seo-component';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const isPreview: any = process.env.REACT_APP_PREVIEW!;
  const environment: any = process.env.NODE_ENV!;
  const pass: any = process.env.REACT_APP_PP!;
  const { search } = useLocation();
  const navigate = useNavigate();
  const isAllowed = sessionStorage.getItem('allow');

  let host = window.location.host;
  let protocol = window.location.protocol;
  let parts = host.split(".");
  let subdomain = "";
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length >= 2) {
    subdomain = parts[0];
    // Remove the subdomain from the parts list
    parts.splice(0, 1);
    console.log(subdomain);
    // Set the location to the new url
    // window.location = protocol + "//" + parts.join(".") + "/" + subdomain;
  }

  const hash = (string: string) => {
    return createHash('sha256').update(string).digest('hex');
  }

  React.useEffect(() => {
    if (subdomain === 'token') {
      navigate('/ERMS')
    }
    console.log(environment);
    if (environment != 'development') {
      if (isPreview) {
        if (isAllowed != hash(pass)) {
          navigate('/dev');
        }
      }
    }
  }, []);

  return (
    <>
      {/* <SEO
        title={title}
        titleTemplate={siteName}
        titleSeparator={`-`}
        description={description}
        image={image}
        pathname={siteUrlPlusSlug}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        author={authorName}
        article={true}
        publishedDate={createdDate}
        modifiedDate={updatedDate}
      /> */}

      <div className='bg-dark d-flex flex-column flex-fill wrapper'>
        {/* <Navbar /> */}
        <main className='d-flex flex-column flex-grow-1'>
          <AuthenticatedRoutesWrapper
            routes={routes}
            unlockRoute={`${routeNames.unlock}${search}`}
          >
            {children}
          </AuthenticatedRoutesWrapper>
        </main>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Layout;
