import React from 'react';
import Counter from '../components/Counter';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import LandingPage from 'components/innerpage/landingpage/LandingPage';

function LP() {
  return (
    <>
      <HeaderOne />
      <main>
        <LandingPage />
      </main>

      <Footer />
    </>
  );
}

export default LP;
