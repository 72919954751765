import React from "react";
import { app } from "../../firebase.config";
import { collection, collectionGroup, query, where, getDocs, getFirestore, limit } from "firebase/firestore";
import moment from 'moment';
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
import * as DappUI from "@elrondnetwork/dapp-core/UI"
import { useGetLoginInfo, useGetAccountInfo} from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import Address from "components/Address";
import NftImage from "components/NftImage"; 



//sort an array of objects by a raceTime 
const sortByTime = (arr) => {
    return arr.sort((a, b) => {
        return a.raceTime - b.raceTime;
    }
    );
}


const TopPlayersListWidget = () => {
    const [topPlayers, setTopPlayers] = React.useState([]);
    const [username, setUsername] = React.useState('');
    const [collectionName, setCollectionName] = React.useState('');
    










    const cut = (user) => {
        return user.substr(0, 6) + '...' + user.substr(-5, 5);
    }

    const count = (number) => {
        //add st nd rd th to the end of the number
        if (number === 1) {
            return number + `st`;
        } else if (number === 2) {
            return number + `nd`;
        } else if (number === 3) {
            return number + `rd`;
        } else {
            return number + `th`;
        }
    }





    React.useEffect(async () => {
        const firestoreDB = getFirestore(app);
        const races = query(collectionGroup(firestoreDB, 'races'), limit(1000));
        const querySnapshot = await getDocs(races);
        const topPlayersArr = [];
        const unsubscribe = querySnapshot.forEach((doc) => {
            const docData = doc.data();
            const endTime = docData.endTime;
            const raceTime = docData.raceTime;
            if (raceTime) {
                // docData.raceTime = docData.endTime - docData.startTime;
                // setTopPlayers(prevState => [...prevState, docData]);
                topPlayersArr.push(docData);
                //order top players by raceTime
                // console.log("Race time:" + docData.raceTime)
            }
        });
        setTopPlayers(sortByTime(topPlayersArr))

        return () => unsubscribe();

    }, []);

    var checkedCollection = false;
    const checkCollection = (nft) => {
        if (nft.collection === collectionName && !checkedCollection) {
            console.log(nft.media[0].url)
            checkedCollection = true;
            return nft.media[0].thumbnailUrl;
        }
    }

    return (
        <>
            {topPlayers.map((player, key) => {

                if (key < 3) {
                    return (
                        <div className='winner-item p-2' key={key}>
                            <div className='winner-thumb'>
                                <NftImage playerAddress={player.address} />
                            </div>
                            <div className='winner-content'>
                                <span className='place'><Address user={player.address} /></span>
                            </div>
                        </div>
                        // <div key={key} className={"d-flex flex-row align-items-center justify-content-around " + (key == 0 ? "top1" : "" + key == 1 ? "top2" : "" + key == 2 ? "top3" : "")}>
                        //     <div className={["col-md-2"]}>
                        //         <span>{count(key + 1)}</span>
                        //     </div>
                        //     <div className="col-md-3">
                        //         <span><Address user={player.address} /></span>
                        //     </div>
                        //     <div className="col-md-3">
                        //         <span>{cut(player.address)} </span>
                        //     </div>
                        //     {/* <div className="col-md-2">
                        //         <span>{player.raceId}</span>
                        //     </div>  */}
                        //     <div className="col-md-2">
                        //         <span>{player.raceName}</span>
                        //     </div>
                        //     <div className="col-md-2">
                        //         <span>{moment.utc(player.raceTime * 1000).format('HH:mm:ss:SSS')}</span>
                        //     </div>
                        // </div>

                    )
                }
            })
            }
        </>
    )
}

export default TopPlayersListWidget;