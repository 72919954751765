import React from 'react';
import CounterArea from './CounterArea';
import GamerArea from './GamerArea';
import OfferArea from './OfferArea';

function Areabg() {
  return (
    <div className='area-bg-two'>
      <GamerArea />
      <OfferArea />
      <CounterArea />
    </div>
  );
}

export default Areabg;
