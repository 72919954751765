import React, { useLayoutEffect, useState } from 'react';
import * as DappUI from "@elrondnetwork/dapp-core/UI"
import { useGetLoginInfo, useGetAccountInfo} from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { Unity, useUnityContext } from "react-unity-webgl";
import { useNavigate, useHistory } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { app } from '../firebase.config';
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
import { collection, query, where, getDocs, doc, getFirestore, onSnapshot } from "firebase/firestore";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Header from '../components/header/HeaderOne';

const firestoreDB = getFirestore(app);

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box sx={{ width: '100%', color: '#ff8503' }}>
                <LinearProgress style={{ height: 10 }} color="inherit" variant="determinate" {...props} />
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const environment = process.env.REACT_APP_ENVIRONMENT;
var collectionsEnv = '';
var elrondUri = '';
if (environment === 'testnet') {
    elrondUri = 'https://testnet-api.elrond.com/';
    collectionsEnv = 'collections_dev';
} else if (environment === 'mainnet') {
    elrondUri = 'https://api.elrond.com/';
    collectionsEnv = 'collections_prod';
} else if (environment === 'devnet') {
    elrondUri = 'https://devnet-api.elrond.com/';
    collectionsEnv = 'collections_dev';
}
const remoteConfig = getRemoteConfig(app);
fetchAndActivate(remoteConfig);
const remoteConfigValue = getValue(remoteConfig, collectionsEnv);
remoteConfig.settings.minimumFetchIntervalMillis = 600000;


const TryGame = () => {
    //generate random number for the game
    const [raceId, setRaceId] = useState(makeid(16));
    const [raceInfo, setRaceInfo] = useState(null);
    const [hasRemoteConfig, setHasRemoteConfig] = useState(false);
    const navigate = useNavigate();
    const { address, account } = useGetAccountInfo();
    const [availableCollections, setAvailableCollections] = useState([]);
    const [unloaded, setUnloaded] = useState(false);

    const remoteValue = remoteConfigValue._value;
    // remoteConfig.defaultConfig = {
    //     "collections_dev": "Welcome"
    // };

    React.useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.keyCode === 116) {
                e.preventDefault();
            }
        });
    }, []);


    const [hasNFT, setHasNFT] = useState("");
    React.useEffect(async () => {
        console.log(address);
        if (!isLoggedIn) {
            navigate('/getting-started');
        }
        
    }, []);

    React.useEffect(async () => {
        if (hasNFT !== "yes") {
            await fetch(elrondUri + 'accounts/' + address + '/nfts').then((res) => {
                res.json().then((data) => {
                    console.log("data: " + data.length);
                    // console.log(remoteValue);

                    // if (remoteValue) {
                    //     const collections = JSON.parse(remoteValue);
                    //     // collections.map(collection => {
                    //     //     console.log(collection);
                    //     // });
                    //     if (data.some(item => item.collection === collections[0].name)) {
                    //         console.log('has nft in collection ' + collections[0].name);
                    //         setHasNFT('yes');
                    //     } else {
                    //         console.log('no nfts')
                    //         setHasNFT('no');
                    //     }
                    // }
                    if (data.some(item => item.collection === "ERMS-663664")) {
                        console.log('has nft in collection CryptoRacers');
                        setHasNFT('yes');
                    }
                    else {
                        navigate('/getting-started');
                        console.log('no nfts')
                        setHasNFT('no');
                    }
                });
            });
        }
    }, [remoteValue]);

    React.useEffect(async () => {
        if (hasNFT === "no") {
            navigate('/getting-started');
        }
    }, [hasNFT]);



    const handleFullScreen = useFullScreenHandle();
    const [width, height] = useWindowSize();
    // const { unityProvider, isLoaded, loadingProgression, sendMessage } = useUnityContext(
    //     isBrowser && {


    //         // loaderUrl: 'https://storage.erms.ro/unitybuild/test/test.loader.js',
    //         // dataUrl: 'https://storage.erms.ro/unitybuild/test/test.data',
    //         // frameworkUrl: 'https://storage.erms.ro/unitybuild/test/test.framework.js',
    //         // codeUrl: 'https://storage.erms.ro/unitybuild/test/test.wasm'

    //         loaderUrl: 'https://storage.erms.ro/unitybuild/2021.3/keyboard/keyboard.loader.js',
    //         dataUrl: 'https://storage.erms.ro/unitybuild/2021.3/keyboard/keyboard.data',
    //         frameworkUrl: 'https://storage.erms.ro/unitybuild/2021.3/keyboard/keyboard.framework.js',
    //         codeUrl: 'https://storage.erms.ro/unitybuild/2021.3/keyboard/keyboard.wasm'
    //     } || isMobile && {
    //         loaderUrl: 'https://storage.erms.ro/unitybuild/2021.3/touch/touch.loader.js',
    //         dataUrl: 'https://storage.erms.ro/unitybuild/2021.3/touch/touch.data',
    //         frameworkUrl: 'https://storage.erms.ro/unitybuild/2021.3/touch/touch.framework.js',
    //         codeUrl: 'https://storage.erms.ro/unitybuild/2021.3/touch/touch.wasm'
    //     });
    const { unityProvider, isLoaded, loadingProgression, sendMessage, unload } = useUnityContext(
        {
            loaderUrl: 'https://storage.erms.ro/unitybuild/2021.3/web/erms.loader.js',
            dataUrl: 'https://storage.erms.ro/unitybuild/2021.3/web/erms.data',
            frameworkUrl: 'https://storage.erms.ro/unitybuild/2021.3/web/erms.framework.js',
            codeUrl: 'https://storage.erms.ro/unitybuild/2021.3/web/erms.wasm'
        });
    // unity instance is ready

    // if (isLoaded) {
    //     const unsubscribe = onSnapshot(queryRace, (querySnapshot) => {
    //         console.log("done")
    //         querySnapshot.forEach((doc) => {
    //             setRaceInfo(doc.data());
    //         });
    //     });
    // }

    React.useEffect(async () => {
        if (unloaded) {
            await unload();
            navigate('/events');
        }
    }, [unloaded]);

    if (address) {
        const queryRace = query(collection(firestoreDB, "users/" + address + "/races"), where("raceId", "==", raceId))

        const unsubscribe = onSnapshot(queryRace, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                if (change.type === "added") {
                    // console.log("New city: ", change.doc.data());
                    // navigate('/profile/race?id=' + raceId);
                    // await unload();
                    setUnloaded(true);
                    // navigate('/events');

                    return;
                }
                if (change.type === "modified") {
                    // await unload();
                    setUnloaded(true);
                    // navigate('/events');
                    return;
                    // console.log("Modified city: ", change.doc.data());
                }
                if (change.type === "removed") {
                    // console.log("Removed city: ", change.doc.data());
                }
            });
        });
    }

    // React.useEffect(async () => {
    //     console.log(raceId);
    //     const queryRace = query(collection(firestoreDB, "users/" + address + "/races"), where("raceId", "==", raceId))

    //     await getDocs(queryRace).then(querySnapshot => {
    //         querySnapshot.forEach((doc) => {
    //             // doc.data() is never undefined for query doc snapshots
    //             console.log(doc.id, " => ", doc.data());
    //             if (doc.data().endTime) {
    //                 console.log("Race finished: " + doc.data().endTime)
    //             }
    //             setRaceInfo(doc.data());
    //         });
    //     })

    //     // const querySnapshot = await getDocs(queryRace);
    //     // querySnapshot.forEach((doc) => {
    //     //     // doc.data() is never undefined for query doc snapshots
    //     //     console.log(doc.id, " => ", doc.data());
    //     //     if(doc.data().endTime) {
    //     //         console.log("Race finished: "+ doc.data().endTime)
    //     //     }
    //     //     setRaceInfo(doc.data());
    //     // });
    // }, []);




    const { isLoggedIn } = useGetLoginInfo();

    // React.useEffect(() => {
    //     handleFullScreen.enter();
    // },[]);

    React.useEffect(async () => {
        if (address) {
            if (isLoaded) {
                sendMessage('JavascriptInterface', 'setAddress', address);
                sendMessage('JavascriptInterface', 'setRaceId', raceId);
                sendMessage('JavascriptInterface', 'setRaceName', 'Alpha Trial');
                if (isMobile) {
                    sendMessage('JavascriptInterface', 'setIsMobile', "true");
                }
            }
            var uriEnv = '';
            if (environment === 'testnet') {
                uriEnv = 'https://testnet-api.elrond.com/accounts/' + address;
            } else if (environment === 'mainnet') {
                uriEnv = 'https://api.elrond.com/accounts/' + address;
            }
            await fetch(uriEnv).then(async (res) => {
                res.json().then((res) => {
                    // console.log(res)
                    if (res.username) {
                        sendMessage('JavascriptInterface', 'setUserTag', res.username);
                    }
                });
            });

        }
    }, [isLoaded]);




    const loadingPercentage = Math.round(loadingProgression * 100);

    return (
        <>
            <Header />
            <FullScreen handle={handleFullScreen}>
                <main>
                    {isLoaded === false && (
                        <div className='game-background'>
                            <div className='loading-overlay d-flex justify-content-center'>
                                <div className='banner-content text-center'>
                                    <div className='title p-2 pt-3 layer animate__animated animate__fadeInUp animate__delay-.4s title-game'>ERMS</div>
                                    {isBrowser && <img src='/assets/img/unity/keyboard.png' className='loading-keyboard' />}
                                    {isMobile && <img src='/assets/img/unity/touch.png' className='loading-touch' />}
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgressWithLabel value={loadingPercentage} />
                                    </Box>
                                    <p className='loading-progress'>Loading... ({loadingPercentage}%)</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={(isLoaded === false && 'd-none ' ) + 'game d-flex justify-content-center align-items-center'}>
                        <BrowserView>
                            <Unity unityProvider={unityProvider} style={{ width: width - 10, height: height - 100 }} />
                        </BrowserView>
                        <MobileView>
                            <Unity unityProvider={unityProvider} style={{ width: width - 10, height: height - 100 }} />
                        </MobileView>
                    </div>
                </main>
            </FullScreen>
        </>
    );
}
export default TryGame;