import React, { Fragment } from 'react';
import './clock.css';
import { useTranslation } from 'react-i18next';

const Clock = ({ timerDays, timerHours, timerMinutes, timerSeconds }) => {
  const { t, i18n } = useTranslation('common');
  return (
    <Fragment>
      <section className='timer container'>
        <div className='timer row'>
          <div className='d-flex flex-row clock '>
            <div className='box'>
              <div className='percent'>
                <svg>
                  <circle cx='40' cy='40' r='40'></circle>
                  <circle cx='40' cy='40' r='40'></circle>
                </svg>
                <div className='ttt'>
                  <h2>{timerDays}</h2>
                </div>
              </div>

              <small>{t('days')}</small>
            </div>
            <div className='box'>
              <div className='percent'>
                <svg>
                  <circle cx='40' cy='40' r='40'></circle>
                  <circle cx='40' cy='40' r='40'></circle>
                </svg>

                <div className='ttt'>
                  <h2>{timerHours}</h2>
                </div>
              </div>

              <small>{t('hours')}</small>
            </div>
            <div className='box'>
              <div className='percent'>
                <svg>
                  <circle cx='40' cy='40' r='40'></circle>
                  <circle cx='40' cy='40' r='40'></circle>
                </svg>
                <div className='ttt'>
                  <h2>{timerMinutes}</h2>
                </div>
              </div>

              <small>{t('minutes')}</small>
            </div>
            <div className='box'>
              <div className='percent'>
                <svg>
                  <circle cx='40' cy='40' r='40'></circle>
                  <circle cx='40' cy='40' r='40'></circle>
                </svg>
                <div className='ttt'>
                  <h2>{timerSeconds}</h2>
                </div>
              </div>

              <small>{t('seconds')}</small>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
Clock.defaultProps = {
  timerDays: 0,
  timerHours: 0,
  timerMinutes: 0,
  timerSeconds: 0
};

export default Clock;
