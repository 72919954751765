import React from "react";
import { useSearchParams, Link } from 'react-router-dom';

const VerifyComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenId = searchParams.get('tokenId');

    return (
        <>
            <h1>Verify {tokenId}</h1>
        </>
    );
}
export default VerifyComponent;