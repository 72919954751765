import $ from 'jquery';
import { dAppName } from 'config';
import FAQ from 'pages/FAQ';
import Marketplace from 'pages/Marketplace';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Profile from 'pages/Profile';
import Roadmap from 'pages/Roadmap';
import Terms from 'pages/Terms';
import withPageTitle from './components/PageTitle';
import AboutUs from './pages/AboutUs';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import GDPR from './pages/GDPR';
import GettingStarted from './pages/GettingStarted';
import Home from './pages/Home';
import IndexOne from './pages/IndexOne';
import IndexThree from './pages/IndexThree';
import IndexTwo from './pages/IndexTwo';
import LogedIn from './pages/LogedIn';
import MatchSingle from './pages/MatchSingle';
import Shop from './pages/Shop';
import ShopDetails from './pages/ShopDetails';
import Squad from './pages/Squad';
import Tournament from './pages/Tournament';
import Transaction from './pages/Transaction';
import Admin from 'pages/Admin';
import Discord from 'redirects/Discord';
import TryGame from 'pages/Try';
import Dev from 'pages/Dev';
import LP from 'pages/ERMSLandingPage';
import CheckoutSuccess from 'pages/CheckoutSuccess';
import TopPlayers from 'pages/TopPlayers';
import Events from 'pages/Events';
import Medium from 'redirects/Medium';
import Verify from 'pages/Verify';
import RaceInfo from 'pages/RaceInfo'; 
import AirDrop from 'pages/AirDrop';
import Buy from 'redirects/Buy';

export const routeNames = {
  home: '/',
  dashboard: '/dashboard',
  marketplace: '/marketplace',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  gettingStarted: '/getting-started',
  profile: '/profile',
  about: '/about-us',
  roadmap: '/roadmap',
  faq: '/faq',
  terms: '/terms',
  privacy: '/privacy-policy',
  gdpr: '/gdpr',
  logedin: '/logedin',
  admin: '/admin',
  discord: '/discord',
  try: '/try',
  shopdetails: '/shop-details',
  dev: '/dev',
  erms: '/erms',
  checkoutSuccess: '/checkout-success',
  topPlayers: '/top',
  events: '/events',
  medium: '/medium',
  verify: '/verify',
  lp: '/lp',
  raceinfo: '/race-info',
  airdrop: 'airdrop',
  buy: 'buy'
};

const routes: Array<any> = [
  { path: routeNames.home, title: 'Home', component: Home },
  { path: routeNames.dashboard, title: 'Dashboard', component: Dashboard, authenticatedRoute: true },
  { path: routeNames.marketplace, title: 'Marketplace', component: Marketplace },
  { path: routeNames.gettingStarted, title: 'Getting Started', component: GettingStarted },
  { path: routeNames.about, title: 'About', component: AboutUs },
  { path: routeNames.roadmap, title: 'Roadmap', component: Roadmap },
  { path: routeNames.profile, title: 'Profile', component: Profile },
  { path: routeNames.faq, title: 'FAQ', component: FAQ },
  { path: routeNames.terms, title: 'Terms', component: Terms },
  { path: routeNames.privacy, title: 'Privacy Policy', component: PrivacyPolicy },
  { path: routeNames.gdpr, title: 'GDPR', component: GDPR },
  { path: routeNames.logedin, title: 'Loged In', component: LogedIn },
  { path: routeNames.admin, title: 'Admin', component: Admin },
  { path: routeNames.discord, title: 'Discord', component: Discord },
  { path: routeNames.try, title: 'ERMS Game', component: TryGame },
  { path: routeNames.shopdetails, title: 'Shop Details', component: ShopDetails },
  { path: routeNames.dev, title: 'Dev', component: Dev },
  { path: routeNames.lp, title: 'ERMS', component: LP },
  { path: routeNames.checkoutSuccess, title: 'Order Completed', component: CheckoutSuccess },
  { path: routeNames.topPlayers, title: 'Top Players', component: TopPlayers },
  { path: routeNames.events, title: 'Events', component: Events },
  { path: routeNames.medium, title: 'Medium', component: Medium },
  { path: routeNames.verify, title: 'Verify', component: Verify },
  { path: routeNames.raceinfo, title: 'Race', component: RaceInfo},
  { path: routeNames.airdrop, title: 'AirDrop', component: AirDrop},
  { path: routeNames.buy, title: 'Buy', component: Buy}
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
