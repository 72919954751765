import React from "react";
import { Link } from 'react-router-dom';
import { useNavigate, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { app } from "../../../firebase.config";
import { collection, query, where, getDocs, getFirestore, limit } from "firebase/firestore";
import moment from "moment";
import TopPlayersList from "../../../components/topPlayers/TopPlayersList";
import * as DappUI from "@elrondnetwork/dapp-core/UI"
import { useGetLoginInfo, useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { refreshAccount } from '@elrondnetwork/dapp-core/utils';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';
import {
    TokenPayment,
    Transaction,
    ESDTTransferPayloadBuilder,
    TransactionPayload,
    Address,
    TransactionWatcher
} from '@elrondnetwork/erdjs';
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { convertToObject } from "typescript";
import { eventsAddress } from '../../../config'



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className='w-100'
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const environment = process.env.REACT_APP_ENVIRONMENT;
var elrondUri = '';
var esdtToken = '';
var chain = '';
var playPrice = 1;
if (environment === 'testnet') {
    elrondUri = 'https://testnet-api.elrond.com/';
    esdtToken = 'TEST-016040';
    chain = 'T';
    playPrice = 1000000000.00000;
} else if (environment === 'mainnet') {
    elrondUri = 'https://api.elrond.com/';
    esdtToken = 'ERMS-6f146b';
    chain = '1';
    playPrice = 10000000000000000000.00000;
} else if (environment === 'devnet') {
    elrondUri = 'https://devnet-api.elrond.com/';
    esdtToken = 'DEV-016040';
    chain = 'D';
    playPrice = 1000000000.00000;
}
let networkProvider = new ApiNetworkProvider(elrondUri);

const Event = ({ type }) => {

    const [value, setValue] = React.useState(0);
    const [events, setEvents] = React.useState([]);
    const [screenWidth, setScreenWidth] = React.useState(document.body.clientWidth);
    const { address, account } = useGetAccountInfo();
    const { isLoggedIn } = useGetLoginInfo();
    const [sessionId, setTransactionSessionId] = React.useState('');
    const navigate = useNavigate();

    React.useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(document.body.clientWidth);
        }
            , false);
    }
        , []);

    React.useEffect(async () => {
        try {
            const firestoreDB = getFirestore(app);
            var q;
            if (type === "upcoming") {
                q = query(collection(firestoreDB, "events"), where("dateStart", ">=", new Date()));
            } else if (type === "practice") {
                q = query(collection(firestoreDB, "events"), where("type", "==", "practice"));
            } else if (type === "ended") {
                q = query(collection(firestoreDB, "events"), where("dateEnd", "<", new Date()));
            }
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                setEvents(events => [...events, doc.data()]);
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark'
        }
    });

    const pay = async () => {
        let payment = TokenPayment.fungibleFromAmount(esdtToken, playPrice, 0);
        let data = new ESDTTransferPayloadBuilder()
            .setPayment(payment)
            .build();
        let transaction = new Transaction({
            nonce: 7,
            receiver: new Address(eventsAddress),
            data: data,
            gasLimit: 60000000,
            chainID: chain
        })

        // const pingTransaction = {
        //   value: '1000000000000000000',
        //   data,
        //   receiver: "erd156k98xat7l33ny9q64nqhyx0weykpexrc23gm9776sl62dw9w40sysw5sh",
        //   gasLimit: '60000000'
        // };
        await refreshAccount();

        const tx = {
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Processing Ping transaction',
                errorMessage: 'An error has occured during Ping',
                successMessage: 'Ping transaction successful'
            },
            redirectAfterSign: false
        }

        const txHash = await sendTransactions(tx);
        if (txHash.sessionId != null) {
            setTransactionSessionId(txHash.sessionId);
            //add txHash to seession storage
            sessionStorage.setItem('txSessionId', txHash.sessionId);
            // const watcher = new TransactionWatcher({
            //     networkProvider,
            //     transactionHash: txHash.sessionId,
            //     onTransactionSuccess: () => {
            //         console.log("Transaction successful");
            //     }
            // });
            // watcher.start();
        }


    }


    React.useEffect(async () => {
        let transactions = JSON.parse(sessionStorage.getItem('persist:dapp-core-transactions'));
        let txSessionId = sessionStorage.getItem('txSessionId');
        let signedTransactions = JSON.parse(transactions.signedTransactions);
        let currentTransaction = signedTransactions[txSessionId];
        // console.log(signedTransactions);
        if (currentTransaction) {
            // console.log(currentTransaction);
            if (currentTransaction.status === 'success') {
                console.log('success');
                navigate('/try');
            }

        }
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="d-flex flex-column flex-md-row">
            <ThemeProvider theme={darkTheme}>
                {/* <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', width: '90vw', height: '100%', paddingLeft: '5px' }}
                > */}
                <Tabs
                    orientation={screenWidth > 760 ? "vertical" : "horizontal"}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="Vertical tabs example"
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                // sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    {events.length ? (
                        events.map((event, index) => (
                            <Tab key={index} label={
                                <div className={'event-item ' + (value == index && 'event-item-active')}>
                                    <div className="event-item-name">
                                        {event.name}
                                    </div>
                                </div>
                            }
                                sx={{ fontFamily: 'Oswald' }}
                                {...a11yProps(index)} />
                        ))) :
                        <>
                            <div className='event-item-skeleton p-2'>
                                <Skeleton animation="wave" height={250} width={250} />
                            </div>
                            <div className='event-item-skeleton p-2'>
                                <Skeleton animation="wave" height={250} width={250} />
                            </div>
                            <div className='event-item-skeleton p-2'>
                                <Skeleton animation="wave" height={250} width={250} />
                            </div>
                        </>
                    }
                </Tabs>
                {events.map((event, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                            <div className='event-type text-uppercase'>
                                {event.type}
                            </div>
                            <div className='d-flex flex-row justify-content-between w-100'>
                                <div className='event-name'>
                                    {event.name}
                                </div>
                                {event.value &&
                                    <div className='event-value'>
                                        {event.currency === 'USD' && '$'}{event.value.toLocaleString()} {event.currency !== 'USD' && event.currency}
                                    </div>
                                }
                            </div>
                            <div className='w-100 p-2 border-bottom border-white'></div>
                            {event.dateStart &&
                                <div className='d-flex flex-row justify-content-between w-100'>
                                    <div className='event-date'>
                                        {event.dateStart.toDate().toLocaleDateString('ro-RO')} - {event.dateStart.toDate().toLocaleTimeString('ro-RO')} UTC+3
                                    </div>
                                    {type === 'upcoming' &&
                                        <div className='event-time-left'>
                                            {moment(event.dateStart.toDate()).fromNow(true)}
                                        </div>
                                    }
                                </div>
                            }
                            <div className='d-flex flex-row justify-content-between w-100'>
                                <div className='w-100 d-flex flex-column event-location'>
                                    {event.locationName}
                                    <div className='w-100 d-flex flex-column flex-md-row justify-content-between p-2'>
                                        <div className='event-image'>
                                            <img src={event.locationThumbnail} alt={event.locationName} className='event-location-thumbnail' />
                                        </div>
                                        <div className='w-100 d-flex flex-column event-info p-2'>
                                            <div className='w-100 d-flex flex-row justify-content-between'>
                                                <div className='event-laps-label'>Laps</div>
                                                <div className='event-laps-value'>{event.laps}</div>
                                            </div>
                                            <div className='w-100 p-2 border-bottom border-white'></div>
                                            <div className='w-100 d-flex flex-row justify-content-between'>
                                                <div className='event-weather-label'>Weather</div>
                                                <div className='event-weather-value'>{event.weather}</div>
                                            </div>
                                            <div className='w-100 p-2 border-bottom border-white'></div>
                                            <div className='w-100 d-flex flex-row justify-content-between'>
                                                <div className='event-penalty-label'>Penalty</div>
                                                <div className='event-penalty-value'>{event.penalty ? 'On' : 'Off'}</div>
                                            </div>
                                            <div className='d-flex flex-row justify-content-start pt-2 event-action-btn'>
                                                {/* <Link to='/try' className='btn btn-event-action'>
                                                    Practice
                                                    <i className='fas fa-play-circle' />
                                                </Link> */}
                                                {isLoggedIn ?
                                                    <button className='btn btn-event-action' onClick={pay}>
                                                        Pay&Play &nbsp; <i className='fas fa-dollar-sign' />10ERMS
                                                    </button>
                                                    :
                                                    <Link to='/try' className='btn btn-event-action'>
                                                        Practice
                                                        <i className='fas fa-play-circle' />
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100 d-flex flex-column flex-md-row justify-content-between p-2'>
                                <div className='w-100 d-flex flex-column p-2'>
                                    <h1>Current standings</h1>
                                    {/* <div className='w-100 p-2 border-bottom border-white'></div> */}
                                    <div className='w-100 standings-list'>
                                        <TopPlayersList />
                                    </div>
                                </div>
                                {/* <div className='w-100 d-flex flex-column p-2'>
                                    Rewards
                                    <div className='w-100 p-2 border-bottom border-white'></div>
                                </div> */}

                            </div>
                        </div>
                    </TabPanel>
                ))}
                {/* </Box> */}
            </ThemeProvider>
        </div>
    );
}

export default Event;