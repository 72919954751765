import React from 'react';
import Typography from '@mui/material/Typography';
import TopPlayersList from './topPlayers/TopPlayersList';

function TopPlayersComponent() {
  return (
    <section className='countdown-area-two countdown-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-12'>
            <div className='countdown-wrap text-center'>
              <div>
                <h1>Top Players</h1>
                <div className='p-2'>
                  <TopPlayersList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopPlayersComponent;
