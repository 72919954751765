import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import AreaBg from '../components/headerone/AreaBg';
import Banner from '../components/headerone/Banner';

function LogedIn() {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/');
  }, []);
  return (
    <>
      <HeaderOne />
      <main></main>
      <Footer />
    </>
  );
}

export default LogedIn;
