import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TrustBox from './TrustPilot';

function Footer() {
  const { t, i18n } = useTranslation('common');

  React.useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);
  return (
    <footer>
      <div className='footer-top-wrap'>
        <div className='container'>
          <div className='footer-menu-wrap'>
            <div className='row align-items-center'>
              <div className='col-sm-2 col-md-3 col-lg-1'>
                <div className='f-logo'>
                  <Link to='/'>
                    <img src='assets/img/logo/logo.png' alt='' />
                  </Link>
                </div>
              </div>
              <div className='align-items-center col-lg-2 '>
                <TrustBox />
              </div>
              <div className='col-sm-10 col-md-9 col-lg-9'>
                <div className='footer-menu'>
                  <nav>
                    <ul className='navigation'>
                      <li>
                        <Link to='/'>{t('Home')}</Link>
                      </li>
                      <li>
                        <Link to='/getting-started'>{t('GettingStarted')}</Link>
                      </li>
                      <li>
                        <Link to='/roadmap'>{t('Roadmap')}</Link>
                      </li>
                      <li>
                        <Link to='/marketplace'>{t('Marketplace')}</Link>
                      </li>
                      <li>
                        <Link
                          to='/medium'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {t('Whitepaper')}
                        </Link>
                      </li>
                    </ul>
                    {/* <div className='footer-search'>
                      <form action='#'>
                        <input type='text' placeholder='Find Here Now' />
                        <button>
                          <i className='fas fa-search' />
                        </button>
                      </form>
                    </div> */}
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-quick-link-wrap'>
            <div className='row align-items-center'>
              <div className='col-lg-7 col-md-8'>
                <div className='quick-link-list'>
                  <ul>
                    <li>
                      <a href='/faq'>{t('FAQ')}</a>
                    </li>
                    <li>
                      <a
                        href='https://t.me/ermsannouncements'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {t('HelpCenter')}
                      </a>
                    </li>
                    <li>
                      <Link to='/terms'>{t('TermsOfUse')}</Link>
                    </li>
                    <li>
                      <Link to='/privacy-policy'>{t('PrivacyPolicy')}</Link>
                    </li>
                    <li>
                      <Link to='/gdpr'>{t('GDPR')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-5 col-md-4'>
                <div className='footer-social'>
                  <ul>
                    <li>
                      <a
                        href='/discord'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-discord' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://fb.me/erms.ro'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-facebook-f' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://twitter.com/erms_ro'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-twitter' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://youtube.com/c/AlinCostaERMS'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-youtube' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://instagram.com/erms.ro'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-instagram' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://tiktok.com/@erms.ro'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-tiktok' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://t.me/ermsannouncements'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-telegram' />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='copyright-text'>
                <p>{t('Copyright')}</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='copyright-img text-center text-md-end'>
                {/* <img src='assets/img/images/cart_img.png' alt='' /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
