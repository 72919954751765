import React from 'react';
import Counter from 'components/Counter';
import Banner from 'components/headerthree/Banner';
import SquadSingle from 'components/innerpage/squad/SquadSingle';
import GamerArea from '../headertwo/GamerArea';
import Newsletter from '../Newsletter';
import Gallery from './Gallery';
// import Live from './Live';
// import Team from './Team';
// import ShopArea from '../headertwo/ShopArea';
import Tournament from './Tournament';
import TrelloBoards from 'components/headerone/TrelloBoards';
import Roadmap from 'components/headerthree/Roadmap';
import GettingStartedComponent from 'components/headertwo/Banner';

function AreaBg() {
  return (
    <>
      <GamerArea />
      <Banner />
      <GettingStartedComponent />
      <Tournament />
      {/* <TrelloBoards /> */}
      {/* <Counter /> */}
      <Roadmap />
      <SquadSingle />
      {/* <Live /> */}
      {/* <ShopArea /> */}
      <Newsletter />
    </>
  );
}

export default AreaBg;
