import React from "react";

const LandingPage = () => {
    return (
        <>
            <section className='countdown-area-two countdown-bg'>
                <div className='container'>
                    <h1>Landing Page</h1>
                </div>
            </section>
        </>
    );
}
export default LandingPage;