import React from "react";
import HeaderOne from "components/header/HeaderOne";
import Footer from "components/Footer";

const Medium = () => {
    React.useEffect(() => {
        window.location.replace("https://medium.com/@alincosta888");
    }, []);
    return (
        <div>
            <HeaderOne />
            <div className='container'>
                <h1>You'll be redirected to Discord. If not, click <a href='https://medium.com/@alincosta888' target='_blank' rel='noreferrer'>here</a></h1>
            </div>
            <Footer />
        </div>
    );
}
export default Medium;