import React from 'react';
import '../../styles/animate.min.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Banner() {
  const { t, i18n } = useTranslation('common');

  React.useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <>
      <div className='overlay'></div>

      <video
        playsInline='playsinline'
        autoPlay='autoplay'
        muted='muted'
        loop='loop'
        className='banner-video'
      >
        <source src='https://firebasestorage.googleapis.com/v0/b/erms-36750.appspot.com/o/video.mp4?alt=media' type='video/mp4' />
      </video>
      <div id='parallax' className='banner-area banner-bg'>
        <div className='container container-banner'>
          <div className='row justify-content-center'>
            <div className='col-xxl-8 col-lg-9'>
              <div className='banner-content text-center'>
                <h2
                  className='title layer animate__animated animate__fadeInUp animate__delay-.4s'
                  data-depth='-0.2'
                >
                  ERMS
                </h2>
                <div className='banner-btn animate__animated animate__fadeInUp animate__delay-.6s'>
                  <Link to='/events' className='btn'>
                    Play now
                    <i className='fas fa-play-circle' />
                  </Link>
                  <a href='/getting-started' className='text-btn'>
                    Elrond Racing Motors Sports
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
