import React from "react";
import HeaderOne from "components/header/HeaderOne";
import Footer from "components/Footer";

const Buy = () => {
    React.useEffect(() => {
        window.location.replace("https://esdt.market/app/esdt/listing/ZMnnLooHIpZYQL1Yw1B0");
    }, []);
    return (
        <div>
            <HeaderOne />
            <div className='container'>
                <h1>You'll be redirected to ESDT Market. If not, click <a href='https://esdt.market/app/esdt/listing/ZMnnLooHIpZYQL1Yw1B0' target='_blank' rel='noreferrer'>esdt.market</a></h1>
            </div>
            <Footer />
        </div>
    );
}
export default Buy;