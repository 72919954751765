import * as React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer';
import HeaderOne from '../../components/header/HeaderOne';
import PageNotFoundGame from './game';

const PageNotFound = () => {
  const { pathname } = useLocation();
  return (
    <>
      <HeaderOne />
      <PageNotFoundGame />
      <Footer />
    </>
  );
};

export default PageNotFound;
