import React from 'react';
const environment = process.env.REACT_APP_ENVIRONMENT;


const Address = ({user}) => {
    // return address.substr(0, 6) + '...' + address.substr(-5, 5);
    const [username, setUsername] = React.useState('Loading Hero Tag... ');

    React.useEffect(() => {
        // console.log(user);
        var uriEnv = 'https://api.erms.ro/api/elrond/username/' + user;
        try {
            fetch(uriEnv, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                response.json().then((response) => {
                    // console.log(response.username)
                    if (response.username) {
                        // sendMessage('JavascriptInterface', 'setUserTag', res.username);
                        // setUsername(res.username)
                        setUsername(response.username)
                        // console.log(res.username)
                    }
                    else {
                        // setUsername(user.substr(0, 6) + '...' + user.substr(-5, 5));
                        setUsername(user.substr(0, 6) + '...' + user.substr(-5, 5));
                    }
                });
            }).catch(err => {
                console.log(err)
            });
        } catch (err) {
            console.log(err)
        }
    }, [user]);

    return username;
};

export default Address;