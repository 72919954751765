import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Event from '../components/innerpage/events/Event';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className='overflow-hidden w-100'
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, width: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function EventsComponent() {
  const [tab, setTab] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <section className='countdown-area-two countdown-bg'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <div>
                <div className='d-flex flex-row'>
                  <Tabs value={tab} onChange={handleChangeTabs} aria-label="basic tabs example">
                    <Tab sx={{ color: '#ffffff', fontFamily: 'Oswald', fontSize:24 }} label={"Upcoming"} {...a11yProps(0)} />
                    <Tab sx={{ color: '#ffffff', fontFamily: 'Oswald', fontSize:24 }} label={"Ended"} {...a11yProps(1)} />
                    <Tab sx={{ color: '#ffffff', fontFamily: 'Oswald', fontSize:24 }} label={"Practice"} {...a11yProps(2)} />
                  </Tabs>
                </div>
                <div className='d-flex flex-row pt-2'>
                  <TabPanel value={tab} index={0}>
                    <Event type='upcoming' />
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <Event type='ended' />
                  </TabPanel>
                  <TabPanel value={tab} index={2}>
                    <Event type='practice' />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EventsComponent;
