import React from 'react';
import Footer from '../components/Footer';
import HeaderOne from '../components/header/HeaderOne';
import Areabg from '../components/headertwo/Areabg';
import GettingStartedComponent from '../components/headertwo/Banner';
import GalleryArea from '../components/headertwo/GalleryArea';
import LiveMatch from '../components/headertwo/LiveMatch';
import PlayerArea from '../components/headertwo/PlayerArea';
import ShopArea from '../components/headertwo/ShopArea';
import Newsletter from '../components/Newsletter';

function GettingStarted() {
  return (
    <>
      <HeaderOne />
      <main>
        <GettingStartedComponent />
        {/* <Areabg />
        <GalleryArea />
        <PlayerArea />
        <LiveMatch />
        <ShopArea />
        <Newsletter /> */}
      </main>

      <Footer />
    </>
  );
}

export default GettingStarted;
